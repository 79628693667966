.p_layout {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding-bottom: 220px;
	padding-top: 80px;
	min-width: 1200px;
	// .p_layout_payInfo {
	//   width: 750px;
	//   height: 78px;
	//   position: relative;
	//   padding: 16px 0;
	//   display: flex;
	//   justify-content: flex-start;
	//   align-items: center;
	//   img {
	//     width: 46px;
	//     height: 46px;
	//   }
	//   .p_layout_payInfo_main {
	//     margin-left: 19px;
	//     display: flex;
	//     justify-content: center;
	//     flex-direction: column;
	//     .p_layout_payInfo_main_topText,
	//     .p_layout_payInfo_main_botText {
	//       height: 17px;
	//       overflow: hidden;
	//       text-overflow: ellipsis;
	//       display: -webkit-box;
	//       -webkit-line-clamp: 1;
	//       -webkit-box-orient: vertical;
	//       margin: 3px 0;
	//       font-size: 12px;
	//       font-weight: 400;
	//       color: #333333;
	//       line-height: 17px;
	//       display: flex;
	//       .p_layout_payInfo_main_botText_text {
	//         max-width: 440px;
	//         height: 17px;
	//         overflow: hidden;
	//         text-overflow: ellipsis;
	//         display: -webkit-box;
	//         -webkit-line-clamp: 1;
	//         -webkit-box-orient: vertical;
	//       }
	//     }
	//     .p_layout_payInfo_main_topText {
	//       font-weight: 600;
	//       font-size: 14px;
	//     }
	//   }
	//   .p_layout_payInfo_price {
	//     position: absolute;
	//     right: 0px;
	//     bottom: 10px;
	//     height: 40px;
	//     display: flex;
	//     justify-content: flex-start;
	//     align-items: flex-end;
	//     color: @primary-color;
	//     .p_layout_payInfo_price_icon {
	//       width: 8px;
	//       height: 17px;
	//       font-size: 12px;
	//       font-weight: 600;
	//       color: @primary-color;
	//       line-height: 17px;
	//       margin-right: 10px;
	//       transform: translateY(-4px);
	//     }
	//     .p_layout_payInfo_price_1 {
	//       font-size: 22px;
	//       font-family: Impact;
	//       letter-spacing: 1px;
	//     }
	//     .p_layout_payInfo_price_2 {
	//       font-size: 12px;
	//       font-family: Impact;
	//       letter-spacing: 1px;
	//     }
	//   }
	// }
	// .main_box {
	// 	width: 750px;
	// 	height: 470px;
	// 	background: #ffffff;
	// 	box-shadow: 0px 0px 35px 1px rgba(217, 217, 217, 0.5);
	// 	box-sizing: border-box;
	// 	display: flex;
	// 	justify-content: flex-start;
	// 	align-items: center;
	// 	flex-direction: column;
	// 	padding: 30px 0;
	// 	.pay_method {
	// 		width: 680px;
	// 		height: 80px;
	// 		border-radius: 2px;
	// 		border: 1px solid #cccccc;
	// 		box-sizing: border-box;
	// 		display: flex;
	// 		justify-content: flex-start;
	// 		align-items: center;
	// 		padding: 0 30px;
	// 		cursor: pointer;
	// 		transition: all 0.3s ease;
	// 		overflow: hidden;
	// 		position: relative;
	// 		margin-bottom: 16px;
	// 		.pay_method_logo {
	// 			width: 48px;
	// 			height: 48px;
	// 			object-fit: contain;
	// 			// border-radius: 50%;
	// 		}
	// 		.pay_method_intro {
	// 			margin-left: 13px;
	// 			.pay_method_intro_title {
	// 				height: 20px;
	// 				font-size: 14px;
	// 				font-weight: 600;
	// 				color: #333333;
	// 				line-height: 20px;
	// 			}
	// 			.pay_method_intro_desc {
	// 				height: 17px;
	// 				font-size: 12px;
	// 				font-weight: 400;
	// 				color: #999999;
	// 				line-height: 17px;
	// 				margin-top: 3px;
	// 			}
	// 		}
	// 		.pay_method_right {
	// 			flex: 1;
	// 			text-align: right;
	// 			display: flex;
	// 			justify-content: center;
	// 			align-items: flex-end;
	// 			flex-direction: column;
	// 			.pay_method_right_balance {
	// 				height: 17px;
	// 				font-size: 12px;
	// 				font-weight: 400;
	// 				color: #333333;
	// 				line-height: 17px;
	// 				margin: 3px 0;
	// 			}
	// 			.pay_method_right_insufficient {
	// 				height: 17px;
	// 				font-size: 12px;
	// 				font-weight: 400;
	// 				color: @primary-color;
	// 				line-height: 17px;
	// 				margin: 3px 0;
	// 			}
	// 		}
	// 		.pay_method_rightTopImg {
	// 			display: none;
	// 		}
	// 	}
	// 	.pay_method_checked {
	// 		border: 1px solid #ed3833;
	// 		.pay_method_rightTopImg {
	// 			position: absolute;
	// 			top: 0;
	// 			right: 0;
	// 			width: 24px;
	// 			height: 24px;
	// 			display: block;
	// 		}
	// 	}
	// 	.main_box_midText {
	// 		margin-top: 16px;
	// 		width: 100%;
	// 		text-align: left;
	// 		padding: 0 36px;
	// 		.main_box_midText_big {
	// 			height: 17px;
	// 			font-size: 12px;
	// 			font-weight: 400;
	// 			color: #333333;
	// 			line-height: 17px;
	// 		}
	// 		.main_box_midText_small {
	// 			height: 17px;
	// 			font-size: 12px;
	// 			font-weight: 400;
	// 			color: #666666;
	// 			line-height: 17px;
	// 		}
	// 	}
	// }
	// .pay_btn {
	// 	margin-top: 30px;
	// 	border-radius: 4px;
	// 	width: 110px;
	// 	height: 36px;
	// }

	.bank_box {
		width: 860px;
		height: 470px;
		background: #ffffff;
		box-shadow: 0px 0px 35px 1px rgba(217, 217, 217, 0.5);
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		padding: 0 35px;
		text-align: left;
		.bank_box_header {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			height: 48px;
			.bank_box_header_title {
				height: 22px;
				font-size: 16px;
				font-weight: 600;
				color: #333333;
				margin-left: 19px;
			}
			.bank_box_header_time {
				font-size: 12px;
				font-weight: 400;
				color: #ff0700;
				margin-left: 20px;
				display: flex;
				align-items: center;
				.ant-statistic {
					display: inline-block;
					font-size: 12px;
					line-height: 1;
					margin: 0 2px;

					.ant-statistic-content {
						font-size: 12px;
						.ant-statistic-content-value {
							font-size: 12px;
							font-weight: 400;
							color: #ff0700;
						}
					}
				}
			}
		}
		.bank_box_header_line {
			width: 100%;
			height: 1px;
			opacity: 0.3;
			background: #979797;
			margin-bottom: 16px;
		}
		.bank_box_step {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			text-align: left;
			width: 100%;
			.bank_box_step_num {
				margin-left: 21px;
				height: 24px;
				font-size: 20px;
				font-family: Impact;
				color: #999999;
				line-height: 24px;
			}
			.bank_box_step_text {
				height: 20px;
				font-size: 14px;
				font-weight: 600;
				color: #333333;
				margin-left: 13px;
			}
		}
		.bank_box_info {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.collection_account_info {
				width: 448px;
				height: 211px;
				background: #fff6e4;
				border-radius: 4px;
				margin-left: 42px;
				box-sizing: border-box;
				.collection_account_info_title {
					width: 100%;
					height: 32px;
					line-height: 32px;
					font-size: 12px;
					font-weight: 600;
					color: #333333;
					box-sizing: border-box;
					padding: 0 15px;
					border-bottom: 1px solid #eee;
					margin-bottom: 14px;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.collection_account_info_item {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 100%;
					box-sizing: border-box;
					padding-left: 24px;
					padding-right: 0px;
					margin-bottom: 15px;
					.collection_account_info_item_key {
						width: 56px;
						height: 20px;
						font-size: 14px;
						font-weight: 600;
						color: #999999;
						margin-right: 20px;
					}
					.collection_account_info_item_value {
						flex: 1;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						height: 20px;
						font-size: 14px;
						font-weight: 600;
						color: #333333;
					}
				}
			}
			.bank_box_info_line {
				// width: 1px;
				height: 154px;
				opacity: 0.3;
				border-right: 1px dashed #979797;
				margin: 0 24px;
			}
			.bank_box_info_right {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				flex-direction: column;
				.bank_box_info_right_text {
					height: 20px;
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					line-height: 20px;
				}
				.bank_box_info_right_btn {
					width: 146px;
					height: 36px;
					border-radius: 4px;
					border: 1px solid #ff0000;
					text-align: center;
					line-height: 34px;
					font-size: 14px;
					font-weight: 400;
					color: #ff0600;
					margin-top: 8px;
					margin-bottom: 23px;
					cursor: pointer;
				}
				.bank_box_info_right_message {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-top: 8px;
					.bank_box_info_right_message_sendbtn {
						margin-left: 20px;
						cursor: pointer;
						width: 48px;
						height: 36px;
						border-radius: 4px;
						border: 1px solid #ff0000;
						font-size: 14px;
						font-weight: 400;
						color: #ff0600;
						line-height: 34px;
						text-align: center;
					}
				}
			}
		}
	}
}
iframe {
	display: none !important;
}
.p_layout_payInfo {
	width: 960px;
	height: 156px;
	background: #f8f8f8;
	border-radius: 2px;
	margin: 24px auto 16px auto;
	padding: 24px 32px;
	.p_layout_payInfo_main_topText {
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #777777;
		line-height: 20px;
		margin-bottom: 16px;
		display: flex;
		&:last-child {
			margin-bottom: 0;
		}
		span {
			color: #333;
		}
	}
	.main_box {
		display: flex;
		.pay_method {
			padding: 10px 12px;
			background: #ffffff;
			border-radius: 2px;
			display: flex;
			align-items: center;
			position: relative;
			.pay_method_logo {
				width: 16px;
				height: 16px;
				margin-right: 8px;
			}
			.pay_method_intro_title {
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 14px;
				color: #333333;
			}
		}
		.pay_method_checked {
			border: 1px solid #2c6bff;
			.pay_method_rightTopImg {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 16px;
				height: 16px;
			}
		}
	}
}
.p_layout_account {
	background: #f8f8f8;
	border-radius: 2px;
	padding: 24px 32px;
	width: 960px;
	margin: 0 auto 16px auto;
	.title {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 14px;
		color: #333333;
		line-height: 20px;
		img {
			width: 14px;
			height: 14px;
			margin-left: 6px;
		}
	}
	.info {
		margin-top: 12px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #777777;
		line-height: 20px;
		span {
			color: #333333;
		}
	}
}
.pay_btn_list {
	display: flex;
	justify-content: flex-end;
	width: 960px;
	.ant-btn-primary {
		background: #2c6bff !important;
		border-color: #2c6bff !important;
		color: #fff !important;
	}
}

.ant-picker {
	width: 100% !important;
}

.pay-upload-modal {
	.showroom-picker {
		width: 100% !important;
	}
	.remind-text {
		width: 448px;
		margin-left: 92px;
		margin-top: -16px;
		background: #fffbe6;
		border: 1px solid #fff1b8;
		padding: 9px 8px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 12px;
		color: #777777;
		line-height: 14px;
		span {
			color: #f42022;
		}
	}
}

@primary-color: #2c6bff;