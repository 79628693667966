.Footer {
  width: 100%;
  min-width: 1200px;
  height: 200px;
  background: #f2f2f2;
  position: fixed;
  bottom: 0px;

  .Footer_area {
    margin: 0 auto;
    width: 877px;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Footer_area_left {
      width: 170px;
      height: 100%;

      img {
        width: 170px;
        // height: 54px;
        margin-top: 52px;
      }
    }

    .Footer_area_right {
      width: 600px;
      color: #666666;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;

      .Footer_area_right_area {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 107px;
        .Footer_area_right_head {
          width: 429px;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 600;
          color: #666666;
        }

        .Footer_area_right_mid {
          text-align: left;
          word-break: break-all;
          width: 100%;
          .Footer_area_right_mid_area {
            display: flex;
            justify-content: space-between;
            div {
              // width: 33%;
              text-align: center;
            }
            .border_lr {
              width: 180px;
              border-left: 1px solid #666666;
              border-right: 1px solid #666666;
            }
          }
        }
      }
    }
  }
}
.hands{
  cursor: pointer;
}
@primary-color: #2c6bff;