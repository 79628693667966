.header {
	width: 100%;
	height: 66px;
	border-bottom: 1px solid #2c6bff;
	.title {
		font-family: PingFangSC, PingFang SC;
		font-weight: 600;
		font-size: 20px;
		color: #333333;
		line-height: 66px;
	}
}

@primary-color: #2c6bff;