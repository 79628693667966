.setPasswordModal {
  .ant-statistic {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    margin: 0 2px;

    .ant-statistic-content {
      font-size: 12px;
      .ant-statistic-content-value {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

.payModal {
  .payModal_btext {
    font-weight: 600;
    font-size: 16px;
  }
  .ant-form-item-label > label {
    font-size: 16px !important;
  }
  .forget {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #48a9f7;
    line-height: 20px;
    margin-top: 14px;
    cursor: pointer;
    display: inline-block;
  }
}

.priceChangeModal{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  
}

@primary-color: #2c6bff;