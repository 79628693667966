.success {
  width: 750px;
  height: 470px;
  background: #ffffff;
  box-shadow: 0px 0px 35px 1px rgba(217, 217, 217, 0.5);
  margin: 0 auto;
  margin-top: 130px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 220px;
  padding-top: 80px;
  .success_top {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }
}

@primary-color: #2c6bff;